//import React from "react";
import React from "react";
import {Container, useMediaQuery, Grid} from "@mui/material";
import Text from "../data-display/text";
import {useTheme} from "@mui/system";
import SecondaryButton from "../buttons/secondary-button";
import {environment} from "../../environments/quickTaskEnvironemt";
import {title, isQuickTaskProject} from "../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;

export default function HowWorkSection() {
  const theme = useTheme();
  const palette_Color = isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main;
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const Sy_step1 =
    "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Ficon_1.webp?alt=media&token=daa3a1cd-b39e-4fc1-9dae-0f11814b3fda";
  const Sy_step2 =
    "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Ficon_2.webp?alt=media&token=85263787-a6f3-4c79-95c0-dc0589ec6542";
  const Sy_step4 =
    "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Ficon_4.png?alt=media&token=6d60a209-5832-4207-a427-b3acec26ac8a";
  const QT_step1 =
    "https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com/o/quickTaskSteps%2FTyping-bro.svg?alt=media&token=e65e8f1f-4f0b-4722-95d8-40951a3daad4";
  const QT_step2 =
    "https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com/o/quickTaskSteps%2FReview.svg?alt=media&token=6a6daf99-b418-4115-adab-ee2cf0d92b15";
  const QT_step4 =
    "https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com/o/quickTaskSteps%2FDone-rafiki.svg?alt=media&token=4bcac9b7-2440-4a5d-aa7f-353962c10b5d";
  const step1 = isQuickTaskProject ? QT_step1 : Sy_step1;
  const step2 = isQuickTaskProject ? QT_step2 : Sy_step2;
  const step4 = isQuickTaskProject ? QT_step4 : Sy_step4;

  return (
    <div>
      {" "}
      <div style={{background: "white"}}>
        <Container>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: smDown ? "0px" : "50px",
            }}
          >
            <Text variant={"h2"} black style={{marginTop: smDown ? "170px" : "50px"}}>
              How It Works
            </Text>
            <div style={{height: "12px"}} />
          </div>
          <div style={{height: "20px"}} />
          <Grid
            container
            style={{
              display: smDown ? "grid" : "flex",
              alignItems: "center",
              flexDirection: "row",
              marginTop: "50px",
              justifyContent: "center",
              textAlign: "center",
              gap: "50px",
            }}
          >
            {smDown ? (
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <Grid item xs={12} sm={12} lg={12} style={{marginBottom: "20px"}}>
                  <img
                    loading={"lazy"}
                    alt={"Post A Task"}
                    src={step1}
                    height={xsDown ? "80px" : "120px"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} style={{marginBottom: "40px"}}>
                  <Text variant={"body1"} style={{marginTop: "10px"}} bold component={"p"}>
                    Post a Task
                  </Text>

                  <Text
                    style={{width: mdDown ? "100%" : "80%"}}
                    variant={"body2"}
                    component={"p"}
                    regular
                  >
                    Describe the job you need done & post it on {title} in 2-3 minutes. Ensure
                    notifications are on to receive alerts about your task.{" "}
                  </Text>
                </Grid>
                <Grid item xs={12} lg={3} style={{marginBottom: "20px"}}>
                  <img
                    loading={"lazy"}
                    alt={"Review Offers"}
                    src={step2}
                    height={xsDown ? "80px" : "120px"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} style={{marginBottom: "40px"}}>
                  <Text variant={"body1"} style={{marginTop: "10px"}} bold component={"p"}>
                    Review Offers
                  </Text>
                  <Text
                    style={{width: mdDown ? "100%" : "80%"}}
                    variant={"body2"}
                    component={"p"}
                    regular
                  >
                    View offers from local, verified, & background checked Taskers. Accept and
                    assign your top choice to book your service.{" "}
                  </Text>
                </Grid>
                <Grid item xs={12} lg={3} style={{marginBottom: "20px"}}>
                  <img
                    loading={"lazy"}
                    alt={"Complete Task"}
                    src={step4}
                    height={xsDown ? "80px" : "120px"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} style={{}}>
                  <Text variant={"body1"} style={{marginTop: "10px"}} bold component={"p"}>
                    Complete Task
                  </Text>
                  <Text
                    style={{width: mdDown ? "100%" : "80%"}}
                    variant={"body2"}
                    component={"p"}
                    regular
                  >
                    Discuss the details with your Tasker in our chat feature where you can safely
                    communicate without giving any private information. What’s next on your list!?{" "}
                  </Text>
                </Grid>
              </Grid>
            ) : (
              <>
                {" "}
                <Grid item xs={12} lg={3} style={{marginBottom: "20px"}}>
                  <img
                    loading={"lazy"}
                    alt={"Post a Task"}
                    src={step1}
                    height={xsDown ? "80px" : "120px"}
                  />

                  <Text variant={"body1"} style={{marginTop: "10px"}} bold component={"p"}>
                    Post a Task
                  </Text>
                  <Text variant={"body2"} component={"p"} regular>
                    Describe the job you need done & post it on {title} in 2-3 minutes. Ensure
                    notifications are on to receive alerts about your task.
                  </Text>
                </Grid>
                <Grid item xs={12} lg={3} style={{marginBottom: "20px"}}>
                  <img
                    loading={"lazy"}
                    alt={"Review Offers"}
                    src={step2}
                    height={xsDown ? "80px" : "120px"}
                  />
                  <Text variant={"body1"} style={{marginTop: "10px"}} bold component={"p"}>
                    Review Offers
                  </Text>
                  <Text variant={"body2"} component={"p"} regular>
                    View offers from local, verified, & background checked Taskers. Accept and
                    assign your top choice to book your service.{" "}
                  </Text>
                </Grid>{" "}
                <Grid item xs={12} lg={3} style={{marginBottom: "25px"}}>
                  <img
                    loading={"lazy"}
                    alt={"Complete Task"}
                    src={step4}
                    height={xsDown ? "80px" : "120px"}
                  />
                  <Text variant={"body1"} style={{marginTop: "10px"}} bold component={"p"}>
                    Complete Task
                  </Text>
                  <Text variant={"body2"} component={"p"} regular>
                    Discuss the details with your Tasker in our chat feature where you can safely
                    communicate without giving any private information. What’s next on your list!?{" "}
                  </Text>
                </Grid>
              </>
            )}
            <Grid
              item
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "50px",
              }}
            >
              <SecondaryButton
                to={"/support/posters/post-a-task/before-posting/how-do-i-post-a-task"}
                background={palette_Color}
                textColor={"white"}
                style={{width: "80%"}}
              >
                {title} Help Center
              </SecondaryButton>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
