import React from "react";
import Text from "../../components/data-display/text"
import StarRating from "../../../../src/components/reviews-ratings/star-rating";
import Avatar from "../avatar/avatar";
import {useTheme} from "@mui/system";
import {useMediaQuery} from "@mui/material";
import {Divider} from "@mui/material"; 
import {Grid} from "@mui/material";
import ID from '../../../../src/assets/badges/id.png';
import phone from '../../../../src/assets/badges/phone.png';
import BackgroundCheck from '../../../../src/assets/badges/background.png';
import FacebookIcon from "../../../../src/assets/badges/face-book.png";
import GoogleIcon from "../../../../src/assets/badges/google.png";
import {Link, navigate} from "gatsby";
import {environment} from "../../environments/quickTaskEnvironemt";
import {isQuickTaskProject, title} from "../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;
const palette_btn_color = isQuickTaskProject ? QuikTaskMainColor : "#6C63FF";
const palette_img_background = isQuickTaskProject ? QuikTaskMainColor : "rgb(66, 103, 178)";
const styles = {
  outline: {
    color: "rgb(187, 194, 220)",
    fontFamily: "'Helvetica Neue', sans-serif",
    textDecoration: "initial",
    fontSize: "11px",
    fontWeight: "bolder",
  },
  text: {
    color: "rgb(84, 90, 119)",
    fontFamily: "'Helvetica Neue', sans-serif",
    fontSize: "14px",
    fontweight: "initial",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    textDecoration: "initial",
    textAlign: "initial",
    verticalAlign: "initial",
    fontStyle: "initial",
    whiteSpace: "initial",
    margin: " 4px 0px 12px",
    padding: "0px",
  },
  button: {
    borderRadius: "160px",
    display: "inline-block",
    textAlign: "center",
    whiteSpace: "nowrap",
    cursor: "pointer",
    boxSizing: "border-box",
    margin: "0px",
    borderStyle: "solid",
    padding: "4px 16px",
    justifyContent: "flex-end",
    fontsize: "9px",
    lineHeight: "20px",
    letterSpacing: " 0.25px",
    borderColor: palette_btn_color,
    background: palette_btn_color,
    width: "200px",
    height: "30px",
    fontWeight: "bolder",
  },
  badge: {
    background: palette_btn_color,
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    padding: "2px",
    marginRight: "15px",
  },
  flex: {
    display: "flex",
  },
};

const ReviewCard = ({review}: {review: any}) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div
        style={{
          background: xsDown ? "" : "rgb(255,255,255)",
          boxShadow: xsDown ? "" : "0 8px 46px -14px rgba(0,0,0,0.07)",
          borderRadius: xsDown ? "" : "14px",
          borderBottom: xsDown ? "1.5px solid rgb(220,220,220)" : "",
          padding: "18px 20px",
          height: "400px",
          width: "280px",
        }}
      >
        <div style={{display: "flex", alignItems: "flex-start"}}>
          <Avatar
            userId={review.uid}
            name={review.name}
            profilePicture={review.profilePicture}
            size={70}
          />
          <div style={{marginLeft: "10px", marginBottom: "30px", lineHeight: "1.0"}}>
            <Text variant={"body2"} medium>
              {review?.name}
            </Text>
            <StarRating totalAverage={5} size={12} />
          </div>
          <div style={{marginLeft: "auto"}} />
        </div>

        <Divider
          style={{marginRight: "100px", width: "220px", marginTop: "15px", color: "lightgray"}}
        ></Divider>

        <Grid style={{display: "flex", justifyContent: "center"}}>
          <div style={{height: "12px"}} />
          <div style={{height: "4px"}} />
          <h2
            style={{
              ...(styles.outline as React.CSSProperties),
            }}
          >
            LATEST REVIEW
          </h2>
        </Grid>
        <Grid style={{display: "flex", justifyContent: "center"}}>
          <Text
            variant={"body2"}
            regular
            style={{
              ...(styles.text as React.CSSProperties),
            }}
          >
            "{review?.text.trim()}"
          </Text>
        </Grid>

        <Grid style={{display: "flex", justifyContent: "center", alignItems: "flex-end"}}>
          <div style={{height: "5px"}}></div>
          <h2
            style={{
              ...(styles.outline as React.CSSProperties),
            }}
          >
            VERIFIED BADGES
          </h2>
        </Grid>
        <Grid style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
          {review.badges.map((v) => {
            if (v === "ID") {
              return (
                <div
                  style={{
                    ...(styles.flex as React.CSSProperties),
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    loading="lazy"
                    src={ID}
                    style={{
                      ...(styles.badge as React.CSSProperties),
                    }}
                    alt={"badge"}
                  />
                </div>
              );
            } else if (v === "BackgroundCheck") {
              return (
                <div
                  style={{
                    ...(styles.flex as React.CSSProperties),
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    loading="lazy"
                    src={BackgroundCheck}
                    style={{
                      ...(styles.badge as React.CSSProperties),
                    }}
                    alt={"badge"}
                  />
                </div>
              );
            } else if (v === "phone") {
              return (
                <div
                  style={{
                    ...(styles.flex as React.CSSProperties),
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    loading="lazy"
                    src={phone}
                    alt={"badge"}
                    style={{
                      ...(styles.badge as React.CSSProperties),
                      background: palette_img_background,
                    }}
                  />
                </div>
              );
            } else if (v === "facebook.com") {
              return (
                <div
                  style={{
                    ...(styles.flex as React.CSSProperties),
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    loading="lazy"
                    src={FacebookIcon}
                    alt={"badge"}
                    style={{
                      ...(styles.badge as React.CSSProperties),
                      background: palette_img_background,
                    }}
                  />
                </div>
              );
            } else if (v === "google.com") {
              return (
                <div
                  style={{
                    ...(styles.flex as React.CSSProperties),
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    loading="lazy"
                    src={GoogleIcon}
                    style={{
                      ...(styles.badge as React.CSSProperties),
                    }}
                    alt={"badge"}
                  />
                </div>
              );
            }
          })}
        </Grid>
      </div>
      <div>
        <button
          onClick={() => navigate(`/profile${review.uid}`)}
          style={{
            ...(styles.button as React.CSSProperties),
            color: "white",
            display: "flex",
            marginTop: "-43px",
            textAlign: "center",
            justifyContent: "center",
            marginLeft: "38px",
          }}
        >
          Request a Quote
        </button>
      </div>
    </>
  );
};

export default ReviewCard;